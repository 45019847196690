import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import CommonService from '@/service/common';
import { PropertyEntityType } from '@/model/enum';

const applicantOptions = [{ name: '创建人', value: 'Creator' }, { name: '申请人', value: 'Applicant' }, { name: '结束人', value: 'End' }];

export class WorkOrderRateEntityModel extends BaseEntityModel {
    @FormControl({
        label: '服务名称',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAllWorkOrderOptions,
        required: true,
        readonly: true
    } as FormControlOptionModel)
    workOrderTypeProcessDefinitionRelationId: string = undefined;
    workOrderTypeProcessDefinitionRelationName: string = undefined;

    @FormControl({
        label: '评价人',
        type: FormControlType.RADIO_GROUP,
        options: applicantOptions,
        required: true
    } as FormControlOptionModel)
    evaluationPersonType: string = undefined;

    @FormControl({
        label: '默认满意度',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.WORK_ORDER_EVALUATION_LEVEL_CONF,
        required: true
    } as FormControlOptionModel)
    defaultEvaluation: string = undefined;
    defaultEvaluationName: string = undefined;

    @FormControl({
        label: '默认评价时间(天）',
        type: FormControlType.NUMBER,
        required: true
    } as FormControlTextModel)
    timeOut: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '服务名称',
                dataIndex: 'workOrderTypeProcessDefinitionRelationName'
            },
            {
                title: '评价人',
                dataIndex: 'evaluationPersonType',
                customRender: (text, record, index) => {
                    return applicantOptions.filter(item => { return item.value === text; })[0].name;
                }
            },
            {
                title: '默认满意度',
                dataIndex: 'defaultEvaluationName'
            },
            {
                title: '默认评价时间（天）',
                dataIndex: 'timeOut'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class WorkOrderRateQueryModel extends QueryPageModel {
    keyWords: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
